.public .header-wrapper {
    position: relative;
    width: 100%;
    min-height: 600px;
    max-height: 600px;
    height: 100%;
    background-image: var(--background-image);
    background-position: center center;
    background-size: 100%;
    animation: zoom-in-out 30s ease infinite;

    &::before{
        background-image: var(--header-shadow);
        position: absolute;
        content: "";
        top: 0;
        right: 0;
        width: 100%;
        max-height: 600px;
        height: 100%;
        pointer-events: none;
    }

    @media (min-width: 768px) and (max-width:991.98px) {
        background-size: cover;
        min-height: 380px;
        &::before{
            min-height: 380px;
        }
    }
    @media (min-width: 576px) and (max-width:767.98px) {
        background-size: cover;
        min-height: 315px;
        &::before{
            min-height: 315px;
        }
    }
    @media (max-width: 757.97px){
        background-size: cover !important;
        min-height: 300px;
        &::before{
            min-height: 300px;
        }
    }

    
}
.public .header-content{
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    min-height: 600px;
    max-height: 600px;
    height: 100%;
    @media (min-width: 768px) and (max-width:991.98px) {
        min-height: 380px !important;
        height: 380px;
    }
    @media (min-width: 576px) and (max-width:767.98px) {
        min-height: 315px !important;
        height: 315px;
    }
    @media (max-width: 757.97px){
        min-height: 300px !important;
        height: 300px;
    }
}
.public .header-content h2{
    line-height: 1.1em;
    font-size: 6rem;
    font-family: "Radio Canada", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
    font-variation-settings: "width" 100;
    @media (max-width: 757.97px){
        font-size: 4rem;
    }
}
.public .header-content h3{
    color: #f5f5f5f5;
    font-size: 2.2rem;
    max-width: 720px;
    font-family: "Barlow", sans-serif;
    font-weight: 100;
    font-style: normal;
    @media (max-width: 757.97px){
        font-size: 1.5rem;
    }
}
.public .header-content .btn
{
    font-size: 1.3rem;
}
.public h3.ctx-title{
    font-family: "Radio Canada", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
    font-variation-settings: "width" 100;
    font-size: 2rem;
    @media (max-width: 757.97px) {
        margin-top: 10px;
        padding-bottom: 10px;
        text-align: center;
    }
}
.public p.ctx-text{
    font-family: "Barlow", sans-serif;
    font-weight: 100;
    font-style: normal;
    text-align: justify;
}
.public .footer
{
    background-color: var(--footer-bg-color);
    padding-top:15px;
    padding-bottom:15px;
}
.public .ftr-ctx-text
{
    font-family: "Barlow", sans-serif;
    font-size: 1.2rem;
}
.public .ftr-ctx-text > a{
    color: var(--text-color);
}
.public .ftr-ctx-social > a > i{
    color: var(--text-color);
    transition: all 0.4s ease-in-out;
}
.public .ftr-ctx-social > a:hover > i{
    transform:  scaleX(1.3) scaleY(1.3); 
}

@keyframes zoom-in-out {
    0% { background-size: 100%; }
    50% { background-size: 110%; }
    100% { background-size: 100%; }
}