:root {
    --bg-color: #151d28;
    --footer-bg-color: #000000;
    --text-color: #fff;
    --header-shadow: linear-gradient(rgba(5,0,31,0.52), rgba(5,0,31,0.52));
    --primary: #0c2bd7;
    --background-image: url('https://static.446.hu/images/hero-bg.jpg');
    --color-black: #000;
}

body{
    background-color: var(--bg-color) !important;
    color: var(--text-color) !important;
}

@import 'bootstrap/dist/css/bootstrap.css';
@import url('./Public.scss');
@import url('./Auth.scss');
@import url('./Internal.scss');
@import url('./overrides.scss');