@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

:root {
    --bgColor: rgba(28, 28, 29, 1);
    --navbarBG: rgba(37, 39, 40, 1);
    --fieldBG: rgba(51, 51, 52, 1);
    --boxBG: rgba(37, 39, 40, 1);
    --textColor: rgba(156, 158, 161, 1);
    --buttonBG: rgba(59, 61, 62, 1);
    --defaultTransition: all 0.2s ease-in-out;
}

/*Globals*/
.navbar.bg-dark .btn-dark {
    --bs-btn-bg: var(--navbarBG);
    --bs-btn-border-color: var(--navbarBG);
}

.btn-dark {
    --bs-btn-bg: var(--bgColor);
    --bs-btn-border-color: var(--bgColor);
}

.btn-dark-2 {
    --bs-btn-bg: var(--fieldBG);
    --bs-btn-border-color: var(--fieldBG);
    --bs-btn-hover-bg: var(--fieldBG);
    --bs-btn-border-hover-color: var(--fieldBG);
    color: var(--textColor);
}

.btn-dark-feed {
    --bs-btn-color: var(--textColor);
    --bs-btn-bg: var(--boxBG);
    --bs-btn-border-color: var(--boxBG);
    --bs-btn-hover-color: var(--textColor);
    --bs-btn-hover-bg: var(--fieldBG);
    --bs-btn-hover-border-color: var(--fieldBG);
    --bs-btn-focus-shadow-rgb: 211, 212, 213;
    --bs-btn-active-color: var(--textColor);
    --bs-btn-active-bg: var(--fieldBG);
    --bs-btn-active-border-color: var(--fieldBG);
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: var(--textColor);
    --bs-btn-disabled-bg: var(--boxBG);
    --bs-btn-disabled-border-color: var(--fieldBG);

    .disabled {
        cursor: not-allowed !important;
    }
}

.offcanvas {
    background-color: var(--bgColor) !important;
    color: var(--textColor) !important;
}

.dashboard {
    margin-top: -13px;
    background-color: var(--bgColor);
    min-height: calc(100vh - 63px);
    color: var(--textColor);
}

.dashboard>.container-fluid {
    margin-top: 75px;
    padding-top: 20px;
}

.navbar.bg-dark {
    background-color: var(--navbarBG) !important;
}

.navbar.bg-dark .form-control {
    background-color: var(--fieldBG) !important;
    border: 0px solid transparent !important;
}

.navbar.bg-dark .input-group-text {
    background-color: var(--fieldBG) !important;
    border: 0px solid transparent !important;
}

.dashboard .dropdown-menu {
    left: -100px !important;
    --bs-dropdown-bg: var(--navbarBG);
    --bs-dropdown-border-color: var(--navbarBG);
    --bs-dropdown-link-hover-bg: var(--fieldBG);
    border-radius: 0px;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3) !important;
    transition: var(--defaultTransition);
}

.dashboard .dropdown-menu .dropdown-item {
    transition: var(--defaultTransition);
}

.sl_listGroup .list-group-item {
    background-color: var(--bgColor);
    color: var(--textColor);
    border: 0px solid transparent;
    transition: var(--defaultTransition);

    &:hover {
        background-color: var(--fieldBG);
    }
}
.list-group.dark, .list-group.dark .list-group-item{
    background-color: var(--bgColor);
    color: var(--textColor);
}

.postDropDown .dropdown-menu.show {
    --bs-dropdown-bg: var(--bgColor);
    --bs-dropdown-link-hover-bg: var(--fieldBG);
}

.postDropDown .dropdown-menu.show button {
    color: #fff;
}

.feedFrame {
    @media (max-width: 768px) {
        margin-left: 10px;
        margin-right: 10px;
    }
}

.feedItem {
    background-color: var(--boxBG);
    color: var(--textColor);
    padding: 15px 25px 15px 25px;
    border-radius: 10px;
}

.feedLink {
    color: var(--textColor);
    text-decoration: none;
}

.form-control.dark,
.form-select.dark {
    background-color: var(--fieldBG);
    border: 0px solid transparent;
    color: var(--textColor);

    &::placeholder {
        color: var(--textColor);
    }
}

.dark.input-group-text {
    background-color: var(--fieldBG);
    border: 1px solid var(--fieldBG);
    color: #fff;
}

.accordion.dark {
    --bs-accordion-color: var(--textColor);
    --bs-accordion-bg: var(--fieldBG);
    --bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
    --bs-accordion-border-color: var(--fieldBG);
    --bs-accordion-btn-color: var(--fieldBG);
    --bs-accordion-btn-bg: var(--fieldBG);
    --bs-accordion-btn-icon: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27none%27 stroke=%27%23c0c3c6%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27%3e%3cpath d=%27M2 5L8 11L14 5%27/%3e%3c/svg%3e);
    --bs-accordion-btn-icon-width: 1.25rem;
    --bs-accordion-btn-icon-transform: rotate(-180deg);
    --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
    --bs-accordion-btn-active-icon: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27none%27 stroke=%27%23c0c3c6%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27%3e%3cpath d=%27M2 5L8 11L14 5%27/%3e%3c/svg%3e);
    --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
    --bs-accordion-body-padding-x: 1.25rem;
    --bs-accordion-body-padding-y: 1rem;
    --bs-accordion-active-color: var(--fieldBG);
    --bs-accordion-active-bg: var(--fieldBG);
}

.accordion.dark .accordion-item .accordion-header button {
    color: var(--textColor);
}

.modal-content,
.modal-body {
    background-color: var(--bgColor);
}

.tox-tinymce {
    border: 2px solid var(--bgColor) !important;
    margin-bottom: 15px;
}

.tox-editor-header,
.tox-toolbar-overlord,
.tox-toolbar__primary,
.tox-tbtn {
    background-color: var(--bgColor) !important;
    color: var(--textColor) !important;
}

.tox-edit-area,
.tox-edit-area iframe {
    background-color: var(--bgColor) !important;
    color: var(--textColor) !important;
}

.tox-edit-area,
.tox-edit-area iframe html body#tinymce p {
    color: var(--textColor) !important;
}

.tox-statusbar {
    display: none !important;
}

.commentItem {
    background-color: var(--fieldBG);
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 10px;
    margin-bottom: 5px;
}

.commentItemInput {
    padding-top: 10px;
    padding-bottom: 10px;
}

.text-right {
    text-align: right;
}

.fbstle-dropdown.dropdown button.dropdown-toggle {
    color: var(--textColor);

    &::after {
        border: 0px solid transparent !important;
    }
}

.profile-cover {
    --bs-gutter-x: 0rem;


}

.profile-cover .row {
    --bs-gutter-x: 0rem;
}

.profile-action-row {
    padding-right: 15px;
}

.container-fluid[data-pathname="/myprofile"] {
    color: var(--textColor);
}

.fb-styled-card {
    background-color: var(--boxBG);
}

.btn-fb-header-btn {
    color: var(--textColor);
    padding-top: 15px !important;
    padding-bottom: 15px !important;
    border: 0px solid transparent;
    border-bottom: 3px solid transparent;
    border-radius: 0px;
    margin-top: -10px;
    margin-bottom: -4px;
    font-weight: bold;
}

.btn-fb-header-btn:hover {
    background-color: #3a3b3c;
}

.btn-fb-header-btn.active {
    color: #0866ff !important;
    border-bottom: 3px solid #0866ff !important;
}

.fb-styled-card {
    background-color: var(--boxBG) !important;
}

.fb-style-title {
    font-weight: bold;
    font-size: 18px;
}

.infoItem {
    margin-left: 15px;
}

.attDeleteButton {
    display: none;
}

.attImageFrame:hover {
    transition: var(--defaultTransition);

    &::before {
        content: '';
        width: 66px;
        height: 64px;
        background-color: rgba(0, 0, 0, 0.250);
        position: absolute;
        top: 0px;
        left: 0px;
    }

    .attDeleteButton {
        display: block;
    }
}

.upload-circle-base {
    display: flex;
    justify-content: center;
    align-items: center;
}

.upload-circle {
    margin-top: 20px;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background-color: #fff;
    color: rgba(0, 0, 0, 0.8);
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.3s;
}

.preview-image {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
}

.profile-thumb,
.rounded-circle.avatar {
    border-radius: 50%;
    object-fit: cover;
}

.feedItem .nav {
    border-bottom: 0px;

    .nav-item button {
        border-radius: 0px;
        background-color: var(--fieldBG);
        border-bottom: 0px;
        color: var(--textColor);
    }

    .nav-item button.active {
        border-color: var(--fieldBG) !important;
        color: #fff;
    }

    .nav-item button:hover {
        border-color: var(--fieldBG) !important;
        background-color: var(--buttonBG);
    }
}

.adsbygoogle {
    background-color: var(--boxBG);
}

.point {
    width: 16px;
    height: 16px;
    border-radius: 8px;
    background-color: #3a3b3c;
    border: 3px solid var(--bgColor);
    position: absolute;
    bottom: 0px;
    right: -12px;

    @media (max-width: 1270px) {
        right: -25px;   
    }
    @media (min-width: 1271px) {
        right: -20px;
    }
}

.userOnlineRow{
    @media (max-width: 1270px) {
        padding-left: 40px !important;
    }
    @media (min-width: 1271px) {
        padding-left: 30px !important;
    }
}

.point.s-2x {
    transform: scale(2);
    bottom: 6px;
    right: -20px
}

.point.online {
    background-color: #3fbb46;
}

.point.leaved {
    background-color: #a1af2e;
}

.point.offline {
    background-color: #3a3b3c;
}

.list-group-item.userListItem {
    background-color: var(--bgColor);
    border: 0px solid var(--bgColor);
    color: var(--textColor);
    cursor: pointer;
    transition: var(--defaultTransition);

    &:hover {
        background-color: var(--boxBG);
    }
}

/*Chat*/
.chat-app {
    position: fixed;
    right: 0px;
    bottom: 0px;
    display: flex;
    flex-direction: row-reverse;
    z-index: 1001;
}

.chat-window {
    background-color: var(--boxBG);
    border-radius: 10px;
    margin: 10px;
    width: 338px;
    display: inline-block;
    z-index: 1001;

    @media (max-width: 768px) {
        width: calc(100vw - 20px);
        height: calc(100vh - 75px);
    }
}

.chat-window.minimized {
    background-color: transparent;
    width: 55px;

    @media (max-width: 768px) {
        height: 50px;
    }

    .profile-thumb{
        
    }

    .chat-header {
        border-radius: 10px;
        background-color: transparent;
    }

    .chat-header .user-block {
        display: none;
    }

    .chat-header .control-block {
        display: none;
    }

    .chat-body {
        display: none;
    }

    .chat-footer {
        display: none;
    }
}

.chat-header {
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: var(--fieldBG);
    color: var(--textColor);
    margin-bottom: 5px;

    button {
        position: relative;
        top: -2px;
    }
}

.chat-body {
    height: 407px;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 10px;

    @media (max-width: 768px) {
        height: calc(100vh - 180px);
    }
}

.chat-footer {
    display: flex;
    align-items: center;

    @media (max-width: 768px) {}

    .input-group input {
        border-top-left-radius: 0;
    }

    .input-group button {
        border-top-right-radius: 0;
    }
}

.chat-footer input {
    width: 100%;
    padding: 5px;
}

.message {
    background-color: var(--fieldBG);
    width: 65%;
    padding-top: 5px;
    padding-left: 10px;
    margin-bottom: 5px;
    border-radius: 10px;
}

.message .small {
    position: relative;
    top: -6px !important;
    font-size: 12px !important;
}

.received {
    float: left;
}

.sended {
    float: right;
    text-align: right;
    padding-right: 10px;
    background-color: var(--bgColor);
}

.user-dd {
    .dropdown-menu.show{
        left: -191px !important;
        width: 246px;
    }
}

.message-dd {
    .dropdown-menu.show {
        left: -175px !important;
        width: 322px;
    }

    a.dropdown-toggle {
        &::after {
            border: 0 !important;
        }
    }
}

.unreaded {
    width: 16px;
    height: 16px;
    background-color: #0866ff;
    border-radius: 50%;
}

/*Messenger*/
.borderLeft {
    border-left: 1px solid var(--textColor);
}

.messenger-left {
    border-top: 1px solid var(--textColor);
    background-color: var(--boxBG) !important;

    .messenger-contacts {
        overflow-y: true;
        height: calc(100vh - 133px);
    }
}

.messenger-title {
    font-weight: bold;
    font-size: 25px;
}

.chat-rooms-list {
    padding-left: 20px;
    padding-right: 20px;
}

.chat-room-link {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 5px;
    border-radius: 10px;
    transition: var(--defaultTransition);
    cursor: pointer;

    .chat-room-icon-frame {
        position: relative;
        padding-left: 0px;
    }

    &:hover {
        background-color: var(--fieldBG);
    }
}

.messenger-mainFrame {
    border-top: 1px solid var(--textColor);
}

.chatFrame {
    .chatTop {
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.275);
        padding-top: 10px;
        padding-left: 10px;
        padding-right: 10px;
        padding-bottom: 10px;
    }

    .chat-messages {
        height: calc(100vh - 180px);
        overflow-y: auto;
    }

    .chat-form {
        padding-top: 5px;
        padding-bottom: 5px;
    }
}

.messenger-message {
    background-color: var(--fieldBG);
    width: 65%;
    padding-top: 5px;
    padding-left: 10px;
    margin-bottom: 5px;
    border-radius: 10px;
}

.messenger-message.sended {
    background-color: var(--boxBG);
}

/*Notification*/
.nav-item.notification {
    .dropdown-menu.show {
        left: -270px !important;
        min-width: 370px;
        padding-left: 10px;
        padding-right: 10px;
    }
}

.qso-control-buttons {
    top: 31px !important;

    @media (max-width: 768px) {
        top: 0px !important;
    }
}

.qso-accordion {
    margin-top: 15px;
}

/*Profile updates*/
.profile-cover.mobile {
    height: 285px;
}

.profile-picture-row {
    position: relative;
    width: 100%;

    .profile-picture {
        position: absolute;
        top: -80px;
        left: 60px;

        @media (max-width: 768px) {
            display: flex;
            flex-wrap: nowrap;
            justify-content: center;
            align-content: flex-start;
            position: absolute;
            left: 0px;
            top: -90px
        };
    }

    .profile-meta {
        position: absolute;
        top: 10px;
        left: 225px;

        @media (max-width:768px) {
            display: flex;
            flex-direction: column;
            align-items: center;
            position: absolute;
            left: 0px;
            top: 70px;
        }
        .profile-username {
            position: absolute;
        }
        .profile-description {
            position:absolute;
            top: 30px;
        }
    }
}

.list-group-item.accordion{
    --bs-accordion-bg: var(--bgColor);
    --bs-accordion-active-bg: var(--bgColor);
    --bs-accordion-active-color: var(--textColor);
    --bs-accordion-border-width: 0;
    padding: 0;
    margin: 0;

    .accordion-header{
        padding: 0;
        margin: 0;

        .accordion-button.collapsed{
            padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
            color: var(--textColor);
        }
        .accordion-button{
            padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x) !important;
            color: var(--textColor) !important;
        }
    }
    .accordion-collapse.collapsing{
        background-color: var(--bgColor) !important;
        margin: 0 !important;
        padding: 0 !important;
    }
    .accordion-collapse.collapse{
        background-color: var(--bgColor) !important;
        margin: 0;
        padding: 0;
        .accordion-body.list-group{
            margin:0;
            padding:0;
            padding-left: 20px;
        }
    }
}

.yagi-image{
    max-width: 450px;
    /*position: relative;
    left: 60px;    
    transform: rotate(30deg) !important;
    padding-top: 105px;
    padding-left: 30px;
    padding-bottom: 60px;*/
}

h2.title, h3.title, .title{
    font-family: "inter", serif;
    font-weight: 200;
}

.breadcrumb .breadcrumb-item a{
    color: var(--textColor) !important;
    text-decoration: none !important;
}
.breadcrumb-item.active {
    color: var(--textColor);
    font-weight: bold;
}
.breadcrumb-item + .breadcrumb-item::before{
    color: var(--textColor);
}

/*Update: 2024-11-29*/
#online-user-search{
    position: relative;
    top: -6px;

    &::after{
        border: 0px solid transparent;
    }
}
#online-user-search.clear-filter{
    left: 0px;
}

.nextEventFrame{
    padding: 0;

    .card-img-overlay{
        background-color: rgba(0, 0, 0, 0.6);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: end;

        a.stretched-link{
            text-decoration: none;
            width: 0px;
            height: 0px;
        }
    }
}

@mixin max-width($px){.max-width-#{$px} { max-width: #{$px}px !important;width: 100% !important;};}
@for $i from 1 through 5 { @include max-width($i * 100); }
.img-float-left{ float:left; margin-right: 5px;}
.form-floating.dark > .form-control:not(:placeholder-shown) ~ label { color: var(--textColor) !important; }
.form-floating.dark label{ &::after{ background-color: transparent !important; } }

