.auth{
    width: 100vw;
    height: 100vh;
}

.auth label{ color: var(--color-black); }
.auth .loginText{
    font-size: 26px;
    text-align: left !important;
}
.auth .loginBox{
    background-color: rgba(46,65,92,0.2);
    padding-top: 30px;
    padding-bottom: 30px;
    border-radius: 10px;
    box-shadow: 5px 5px 5px rgba(0,0,0,0.5);
}
.dashboard .container-fluid.auth{
    margin-top: 0px !important;
}